import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Link.css';
import logo from '../../newline.webp';

function Link() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [darkMode, setDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => setDarkMode(mediaQuery.matches);
    
    mediaQuery.addListener(handleChange);
    
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  // Extract sessionToken and state from the URL
  const queryParams = new URLSearchParams(window.location.search);
  const sessionToken = queryParams.get('session_token');
  const state = queryParams.get('state');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}${window.location.search}`
    );
    try {
      const response = await axios.post('https://newline.q9.com.br/api/auth', {
        username,
        password,
        session_token: sessionToken,
        state: state
      });

      if (response.data.redirect_to) {
        window.location.href = response.data.redirect_to;
      } else {
        setErrorMessage('Erro ao tentar autenticar com esses dados.');
      }
    } catch (error) {
      setErrorMessage('Erro ao tentar autenticar com esses dados.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`page-link ${darkMode ? 'dark' : 'light'}`}>
      <div className="container">
        <img src={logo} alt="Newline" className='logo' />
        <p>Credenciais do Aplicativo</p>
        {errorMessage && <div className="error">{errorMessage}</div>}
        <form onSubmit={handleSubmit} action="login">
          <div className="input-group">
            <input 
              type="text"
              name="username"
              placeholder="Usuário" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input 
              type={passwordVisible ? 'text' : 'password'} 
              name="password"
              className="form-control"
              placeholder="Senha" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
            <button 
              type="button" 
              className="toggle-password-visibility"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
);
}

export default Link;
