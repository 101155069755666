import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import Link from './pages/link/Link';
import Privacy from './pages/privacy/Privacy'

function LinkRouteWrapper() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get('state');
  const sessionToken = queryParams.get('session_token');

  if (state && sessionToken) {
    return <Link />;
  }

  return <Navigate to="/" replace />;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/link" element={<LinkRouteWrapper />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;