import React, { useState, useEffect } from 'react';
import './Privacy.css';
import logo from '../../newline.webp';

function Privacy(props) {
  const [darkMode, setDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    document.title = props.title || `Política de Privacidade | Newline Portões`;
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => setDarkMode(mediaQuery.matches);
    
    mediaQuery.addListener(handleChange);
    
    return () => mediaQuery.removeListener(handleChange);
  }, [props.title]);

  return (
    <div className={`page-privacy ${darkMode ? 'dark' : 'light'}`}>
      <div className="container">
        <img src={logo} alt="Newline" className='logo' />
        <h1>Política de Privacidade</h1>
        <section>
          <h2>1. Introdução</h2>
          <p>
            A Q9 Tecnologia está comprometida com a proteção da privacidade dos seus usuários. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos, compartilhamos e protegemos suas informações pessoais.
          </p>
        </section>
        <section>
          <h2>2. Coleta de Informações</h2>
          <p>
            Coletamos informações pessoais que você nos fornece diretamente, como nome, endereço de e-mail, e informações de pagamento, assim como informações coletadas automaticamente, como seu endereço IP e comportamento de navegação.
          </p>
        </section>
        <section>
          <h2>3. Uso das Informações</h2>
          <p>
            Utilizamos suas informações pessoais para fornecer e melhorar nossos serviços, processar transações, comunicar-nos com você, e para fins de segurança e conformidade.
          </p>
        </section>
        <section>
          <h2>4. Compartilhamento de Informações</h2>
          <p>
            Podemos compartilhar suas informações pessoais com terceiros confiáveis para fornecer nossos serviços, cumprir obrigações legais, proteger nossos direitos e prevenir fraudes. Nunca vendemos suas informações pessoais.
          </p>
        </section>
        <section>
          <h2>5. Armazenamento e Proteção de Dados</h2>
          <p>
            Armazenamos suas informações pessoais em servidores seguros e implementamos medidas de segurança técnicas e organizacionais adequadas para proteger seus dados contra acesso não autorizado, uso indevido ou divulgação.
          </p>
        </section>
        <section>
          <h2>6. Seus Direitos</h2>
          <p>
            Você tem o direito de acessar, corrigir, excluir e restringir o processamento de suas informações pessoais, além do direito de portabilidade de dados e de se opor ao processamento. Para exercer esses direitos, entre em contato conosco.
          </p>
        </section>
        <section>
          <h2>7. Alterações a Esta Política</h2>
          <p>
            Podemos atualizar esta Política de Privacidade periodicamente para refletir mudanças em nossas práticas ou em leis aplicáveis. Notificaremos você sobre quaisquer mudanças significativas.
          </p>
        </section>
        <section>
          <h2>8. Contato</h2>
          <p>
            Se você tiver dúvidas ou preocupações sobre nossa Política de Privacidade, entre em contato conosco em contato@q9.com.br.
          </p>
        </section>
      </div>
    </div>
  );
}

export default Privacy;
