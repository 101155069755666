import React from 'react';
import './Home.css';
import logo from '../../newline.webp';

function Home() {
  return (
    <div className="home-container">
      <img src={logo} alt="Newline" />
    </div>
  );
}

export default Home;